<script setup lang="ts">
import { useElementSize } from "@vueuse/core";

const props = defineProps({
  user: {
    type: Object as PropType<{
      firstname: string;
      lastname: string;
      image: string;
      [key: string]: any;
    }>,
    required: true,
  },
});

const fit = ref<any>(null);
const fitText = ref<any>(null);

const { width } = useElementSize(fit);
watch(width, () => {
  fitText.value.style.fontSize = `${width.value / 2}px`;
});
</script>

<template>
  <div
    v-if="props.user.image !== ''"
    class="aspect-square overflow-hidden rounded-full shadow-inner"
  >
    <img
      class="h-full w-full object-cover"
      :src="props.user.image"
      :alt="props.user.image"
    />
  </div>
  <div
    v-else
    ref="fit"
    class="aspect-square overflow-hidden rounded-full shadow-inner"
  >
    <div
      class="flex h-full w-full items-center justify-center bg-primary bg-opacity-10"
    >
      <p ref="fitText" class="font-medium text-primary">
        {{ props.user.firstname[0].toUpperCase()
        }}{{ props.user.lastname[0].toUpperCase() }}
      </p>
    </div>
  </div>
</template>
