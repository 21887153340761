<script setup lang="ts">
import { ClockIcon } from "@heroicons/vue/24/outline";

const dayjs = useDayjs();
const { $client } = useNuxtApp();

const { data } = await $client.stripe.status.useQuery();

// useIntervalFn(() => {
//   refresh();
// }, 5000);
</script>

<template>
  <div class="mt-5 flex justify-center">
    <div class="h-9 w-full lg:w-7/12">
      <div
        v-if="!data.isActive || data.isTrial"
        class="inline-flex h-full w-full items-center justify-center gap-x-3 rounded-lg"
        :class="[data.isTrial ? 'bg-orange-100' : 'bg-red-200']"
      >
        <ClockIcon class="h-6 w-6 stroke-icon" />
        <template v-if="data.isTrial && data.isActive">
          <p>
            {{ dayjs(data.periodEnd).diff(new Date(), "day") }}
            {{
              dayjs(data.periodEnd).diff(new Date(), "day") === 1
                ? "verbleibender"
                : "verbleibende"
            }}
            Tage in der Testphase -
            <NuxtLink
              class="cursor-pointer font-bold hover:underline"
              to="/products/tarif-waehlen"
            >
              Tarif wählen
            </NuxtLink>
          </p>
        </template>
        <template v-else-if="data.isTrial && !data.isActive">
          testphase abgelaufen
          <p>
            Deine Testphase ist abgelaufen -
            <NuxtLink
              class="cursor-pointer font-bold hover:underline"
              to="/products/tarif-waehlen"
            >
              Tarif wählen
            </NuxtLink>
          </p>
        </template>
        <template v-else>
          <p>
            Deine Subscription ist abgelaufen -
            <NuxtLink
              class="cursor-pointer font-bold hover:underline"
              to="/products/tarif-waehlen"
            >
              Tarif wählen
            </NuxtLink>
          </p>
        </template>
      </div>
      <!-- {{ data }} -->
    </div>
  </div>
</template>
