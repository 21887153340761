<script setup lang="ts">
const emit = defineEmits(["input"]);
const props = defineProps({
  to: {
    type: String,
    default: "",
  },
  exact: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();

const isActive = computed(() => {
  if (props.exact) {
    return route.path === props.to;
  }
  return route.path.startsWith(props.to);
});
</script>

<template>
  <div class="">
    <NuxtLink
      :to="props.to"
      class="flex items-center gap-x-1 whitespace-nowrap rounded-full border px-2.5 py-2 transition-all duration-150"
      :class="[
        isActive
          ? 'active border-tertiary bg-tertiary'
          : 'inactive  border-transparent hover:isolate hover:border-secondary',
      ]"
      @click="emit('input')"
    >
      <slot />
    </NuxtLink>
  </div>
</template>

<style scoped>
a.active :slotted(svg) {
  @apply fill-icon;
}
a.inactive :slotted(svg) {
  @apply fill-icon-inactive;
}
</style>
